// src/backend/emailService.js
import { config } from '../../../src/backend/configConexaoGeral.js'
import { fetchData } from '../../../src/backend/model.js'

export const enviarEmail = async ({ to, subject, message, sistema }) => {
  const url = config.host + '/tenants'
  const dados = {
    requisicao: 'sendInternalEmail',
    to,
    subject,
    message,
    sistema
  }
  console.log('dados', dados)
  console.log('url', url)

  try {
    const data = await fetchData(url, dados)
    return data
  } catch (error) {
    throw new Error('Erro ao enviar e-mail: ' + error.message)
  }
}
