import { useState, useEffect, useContext } from 'react'
import { Form, Button, Container } from 'react-bootstrap'
import { fetchData } from '../../../../backend/model.js'
import { config } from '../../../../backend/configConexaoGeral'
import { AuthContext } from '../../../../context/AuthContext.jsx'
import { ShowAlertGeneric } from '../../../../utils/form-utils.jsx'

const RegrasPonto = () => {
  const { tenantId } = useContext(AuthContext)
  const [cargos, setCargos] = useState([])
  const [cargoSelecionado, setCargoSelecionado] = useState('')
  const [minutosCafeManha, setMinutosCafeManha] = useState('')
  const [minutosCafeTarde, setMinutosCafeTarde] = useState('')
  const [minutosAlmoco, setMinutosAlmoco] = useState('')
  const [regraExistenteId, setRegraExistenteId] = useState(null)
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertVariant, setAlertVariant] = useState('')

  useEffect(() => {
    const fetchCargos = async () => {
      const url = `${config.host}/gerenciarCargos`
      const response = await fetchData(url, { tenantId, requisicao: 'listarTodosOsCargos' })
      setCargos(response.cargos || [])
    }
    fetchCargos()
  }, [tenantId])

  // Função para buscar regras existentes ao selecionar um cargo
  const buscarRegrasPorCargo = async cargoId => {
    if (!cargoId) {
      setMinutosCafeManha('')
      setMinutosCafeTarde('')
      setMinutosAlmoco('')
      setRegraExistenteId(null)
      return
    }

    try {
      const url = `${config.host}/regrasPonto`
      const response = await fetchData(url, { tenantId, requisicao: 'listarRegrasPonto' })

      const regraEncontrada = response.find(regra => regra.cargoId._id === cargoId)

      if (regraEncontrada) {
        setMinutosCafeManha(regraEncontrada.minutosCafeManha)
        setMinutosCafeTarde(regraEncontrada.minutosCafeTarde)
        setMinutosAlmoco(regraEncontrada.minutosAlmoco)
        setRegraExistenteId(regraEncontrada._id) // Salva o ID da regra existente
      } else {
        setMinutosCafeManha('')
        setMinutosCafeTarde('')
        setMinutosAlmoco('')
        setRegraExistenteId(null) // Indica que não há regra existente
      }
    } catch (error) {
      console.error('Erro ao buscar regras de ponto:', error)
    }
  }
  useEffect(() => {
    if (cargoSelecionado) {
      buscarRegrasPorCargo(cargoSelecionado)
    }
  }, [cargoSelecionado])
  // const handleSubmit = async () => {
  //   try {
  //     const url = `${config.host}/regrasPonto`
  //     const dados = {
  //       requisicao: 'criarRegrasPonto',
  //       cargoId: cargoSelecionado,
  //       minutosCafeManha: Number(minutosCafeManha),
  //       minutosCafeTarde: Number(minutosCafeTarde),
  //       minutosAlmoco: Number(minutosAlmoco),
  //       tenantId
  //     }

  //     await fetchData(url, dados)

  //     // Configurações para o ShowAlertGeneric
  //     setAlertMessage('Regra de ponto cadastrada com sucesso!')
  //     setAlertVariant('success')
  //     setShowAlert(true)

  //     // Resetar campos após o sucesso
  //     setCargoSelecionado('')
  //     setMinutosCafeManha('')
  //     setMinutosCafeTarde('')
  //     setMinutosAlmoco('')
  //   } catch (error) {
  //     setAlertMessage('Erro ao cadastrar a regra de ponto. Tente novamente.')
  //     setAlertVariant('danger')
  //     setShowAlert(true)
  //     console.error('Erro ao criar regra de ponto:', error)
  //   }
  // }
  const handleSubmit = async () => {
    try {
      const url = `${config.host}/regrasPonto`
      const dados = {
        requisicao: regraExistenteId ? 'atualizarRegrasPonto' : 'criarRegrasPonto',
        cargoId: cargoSelecionado,
        minutosCafeManha: Number(minutosCafeManha),
        minutosCafeTarde: Number(minutosCafeTarde),
        minutosAlmoco: Number(minutosAlmoco),
        tenantId
      }

      if (regraExistenteId) {
        dados.regraId = regraExistenteId // Enviar o ID para atualização
      }

      await fetchData(url, dados)

      setAlertMessage(
        regraExistenteId
          ? 'Regra de ponto atualizada com sucesso!'
          : 'Regra de ponto cadastrada com sucesso!'
      )
      setAlertVariant('success')
      setShowAlert(true)
    } catch (error) {
      setAlertMessage('Erro ao salvar a regra de ponto. Tente novamente.')
      setAlertVariant('danger')
      setShowAlert(true)
      console.error('Erro ao salvar regra de ponto:', error)
    }
  }
  return (
    <Container>
      <h2>Cadastro de Regras de Ponto</h2>
      {showAlert && (
        <ShowAlertGeneric
          showAlert={showAlert}
          message={alertMessage}
          variant={alertVariant}
          setShowAlert={setShowAlert}
          timeOut={4000} // 4 segundos
        />
      )}
      <Form>
        <Form.Group>
          <Form.Label>Cargo</Form.Label>
          <Form.Control
            as="select"
            value={cargoSelecionado}
            onChange={e => setCargoSelecionado(e.target.value)}
          >
            <option value="">Selecione um cargo</option>
            {cargos.map(cargo => (
              <option key={cargo._id} value={cargo._id}>
                {cargo.nome}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Minutos para Café da Manhã</Form.Label>
          <Form.Control
            type="number"
            value={minutosCafeManha}
            onChange={e => setMinutosCafeManha(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Minutos para Café da Tarde</Form.Label>
          <Form.Control
            type="number"
            value={minutosCafeTarde}
            onChange={e => setMinutosCafeTarde(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Minutos para Almoço</Form.Label>
          <Form.Control
            type="number"
            value={minutosAlmoco}
            onChange={e => setMinutosAlmoco(e.target.value)}
          />
        </Form.Group>
        <Button variant="primary" onClick={handleSubmit}>
          Salvar
        </Button>
      </Form>
    </Container>
  )
}

export default RegrasPonto
