// AuthContext.jsx
import { createContext, useState, useEffect, useCallback, useMemo } from 'react'
import { fetchData } from '../backend/model'
import { config } from '../backend/configConexaoGeral'

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [idNivelAcesso, setIdNivelAcesso] = useState(null)
  const [nivel_acesso, setNivelAcesso] = useState(null) // Adicionado estado para nivel_acesso
  const [telasPermissao, setTelasPermissao] = useState(null)
  const [user, setUser] = useState(null)
  const [password, setSenha] = useState(null)
  const [RotaBase, setRotaBase] = useState(null)
  const [superUser, setSuperUser] = useState(false)
  const [tenantId, setTenantId] = useState(null)
  const [permissoesCarregadas, setPermissoesCarregadas] = useState(false)
  const [assinaturaStatus, setAssinaturaStatus] = useState('false')
  const [nomeCliente, setNomeCliente] = useState(null)
  const [email, setEmail] = useState(null)
  const [identificacaoFiscal, setIdentificacaoFiscal] = useState(null)
  const [tipodocumento, setTipoDocumento] = useState(null)
  const [acessoModulos, setAcessoModulos] = useState([])
  const login = useCallback(() => {
    setIsAuthenticated(true)
  }, [])

  const logout = useCallback(() => {
    setIsAuthenticated(false)
    setIdNivelAcesso(null) // Resetar idNivelAcesso ao fazer logout
    setNivelAcesso(null) // Resetar nivel_acesso ao fazer logout
    setTelasPermissao(null)
  }, [])

  const carregarPermissoes = useCallback(
    //
    async (idNivelAcesso, RotaBase, tenantId) => {
      // if (!idNivelAcesso && ) return

      let dados = {
        requisicao: 'retornar_todos_niveis',
        id_nivel: idNivelAcesso,
        rota_base: RotaBase,
        tenantId: tenantId
      }
      let url = config.host + '/config_nivel_acesso'

      try {
        let response = await fetchData(url, dados)

        setTelasPermissao(response)
        setPermissoesCarregadas(true)
      } catch (error) {
        console.error('Erro ao carregar permissões', error)
      }
    },
    [idNivelAcesso, RotaBase, tenantId]
  )
  const checkAuth = useCallback(async () => {
    if (!config.host) return
    setIsLoading(true)
    try {
      const url = config.host + '/verificarToken'

      const dados = { requisicao: 'verificarToken', credentials: 'include' }

      const response = await fetchData(url, dados)

      // Verifica se a autenticação foi bem-sucedida

      if (response.success) {
        setIdNivelAcesso(response.id_nivel_acesso)
        setNivelAcesso(response.nivel_acesso) // Define o estado de nivel_acesso
        setRotaBase(response.rota_base)
        setTenantId(response.tenantId)
        setUser(response.userName)
        setSenha(response.senha)
        setIsAuthenticated(true)
        setAssinaturaStatus(response.assinaturaStatus)
        setNomeCliente(response.nome)
        setEmail(response.email)
        setIdentificacaoFiscal(response.identificacaoFiscal)
        setTipoDocumento(response.tipodocumento)
        setAcessoModulos(response.acessoModulos)
        if (!permissoesCarregadas) {
          await carregarPermissoes(response.id_nivel_acesso, response.rota_base, response.tenantId) // Chama a função carregarPermissoes
        }
      } else {
        // Se a autenticação falhou, redireciona para a página de login
        setIsAuthenticated(false)
        if (!RotaBase) {
          setRotaBase(null) // Só reseta se não houver um valor já definido
        }
        setTenantId(null)
        setNivelAcesso(null) // Reseta o estado de nivel_acesso
        setAssinaturaStatus('false')

        // window.location.href = '/modelagemcompleta';
      }
    } catch (error) {
      console.error('Erro ao verificar autenticação', error)

      setIsAuthenticated(false)
      // Opcional: Você pode redirecionar para a página de login aqui também,
      // caso a comunicação com o servidor falhe completamente.
    } finally {
      setIsLoading(false)
    }
  }, [
    carregarPermissoes,
    permissoesCarregadas,
    setIdNivelAcesso,
    setIsAuthenticated,
    setIsLoading,
    setNivelAcesso,
    setRotaBase,
    setTenantId,
    RotaBase
  ])

  // Verificar a autenticação quando o AuthProvider é montado
  useEffect(() => {
    // if (!superUser)

    checkAuth()
  }, [checkAuth])

  const contextValue = useMemo(
    () => ({
      isLoading,
      setIsLoading,
      isAuthenticated,
      setIsAuthenticated,
      login,
      logout,
      checkAuth,
      idNivelAcesso, // Inclui o valor de idNivelAcesso
      setIdNivelAcesso, // Inclui a função setIdNivelAcesso
      setNivelAcesso,
      nivel_acesso, // Inclui o valor de nivel_acesso
      telasPermissao,
      password,
      setSenha,
      user,
      setUser,
      carregarPermissoes,
      RotaBase,
      setRotaBase,
      setSuperUser,
      superUser,
      setTenantId,
      tenantId,
      assinaturaStatus,
      nomeCliente,
      email,
      identificacaoFiscal,
      tipodocumento,
      acessoModulos,
      setAcessoModulos,
      setNomeCliente,
      setEmail,
      setIdentificacaoFiscal
    }),
    [
      isLoading,
      isAuthenticated,
      setIsAuthenticated,
      login,
      logout,
      checkAuth,
      idNivelAcesso,
      setIdNivelAcesso,
      setNivelAcesso,
      nivel_acesso,
      telasPermissao,
      password,
      setSenha,
      user,
      setUser,
      carregarPermissoes,
      RotaBase,
      setRotaBase,
      setSuperUser,
      superUser,
      setTenantId,
      tenantId,
      assinaturaStatus,
      nomeCliente,
      email,
      identificacaoFiscal,
      tipodocumento,
      acessoModulos,
      setAcessoModulos,
      setNomeCliente,
      setEmail,
      setIdentificacaoFiscal
    ]
  )

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}
//-------------------------------------------------------------------------------------------------
