//configConexaoGeral.js
//codigo para recuperaçao do twilio 8XYP1TVBU8BBEW1ZEFQZWK9K
import { DB_CONNECTION_STRING_remota, DB_CONNECTION_STRING_local } from './mongodb/variaveis.js'

export let config = {
  DB_CONNECTION_STRING: '',
  host: '',
  tipo: 0
}

//= 'https://www.modelagemcompleta.com.br'
export const TipoConexao = ({ tipo, dominio = 'https://www.modelagemcompleta.com.br' }) => {
  if (tipo === 1) {
    config.DB_CONNECTION_STRING = DB_CONNECTION_STRING_remota
    config.host = dominio

    config.tipo = 1
  } else if (tipo === 2) {
    config.DB_CONNECTION_STRING = DB_CONNECTION_STRING_local
    config.host = 'http://localhost:3000'
    config.tipo = 2
  }
}
