import { useState, useEffect, useContext } from 'react'
import { Container, Form, Button, Table, Spinner } from 'react-bootstrap'
import { AuthContext } from '../../../../../context/AuthContext.jsx'
import { fetchData } from '../../../../../backend/model.js'
import { config } from '../../../../../../src/backend/configConexaoGeral'
import { ShowAlertGeneric } from '../../../../../utils/form-utils.jsx'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { fetchRelatorioDetalhado } from '../../../../../../src/components/pages/RELOGIODEPONTO/Relatorios/Frequencia/relatorio-frequencia.js'
import { ModalTabelaFuncionarios } from '../../../../../../src/components/pages/RELOGIODEPONTO/Relatorios/Frequencia/relatorio-frequencia.jsx'
import * as XLSX from 'xlsx'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
const BancoDeHoras = () => {
  const { RotaBase, tenantId } = useContext(AuthContext)

  // Estados
  const [dataInicio, setDataInicio] = useState(null)
  const [dataFim, setDataFim] = useState(null)
  const [funcionarioId, setFuncionarioId] = useState('')
  const [funcionarios, setFuncionarios] = useState([])
  const [loading, setLoading] = useState(false)
  const [dadosBancoHoras, setDadosBancoHoras] = useState([])
  const [alertMessage, setAlertMessage] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [alertVariant, setAlertVariant] = useState('success')
  const [showModal, setShowModal] = useState(false)
  const [funcionarioSelecionado, setFuncionarioSelecionado] = useState(null)
  const [dadosDetalhados, setDadosDetalhados] = useState([])
  const [dataIntervalo, setDataIntervalo] = useState([null, null])
  const [mostrarSubmenu, setMostrarSubmenu] = useState(false)
  // Buscar lista de funcionários
  useEffect(() => {
    const fetchFuncionarios = async () => {
      try {
        const url = config.host + '/funcionariosAtivos'
        const response = await fetchData(url, {
          tenantId,
          rota_base: RotaBase,
          requisicao: 'listarFuncionarios'
        })
        setFuncionarios(response || [])
      } catch (error) {
        console.error('Erro ao buscar funcionários:', error)
      }
    }

    fetchFuncionarios()
  }, [RotaBase, tenantId])

  // Função para calcular o saldo de horas
  const handleCalcularBancoHoras = async () => {
    if (!dataInicio || !dataFim) {
      setAlertMessage('Por favor, selecione um intervalo de datas.')
      setAlertVariant('danger')
      setShowAlert(true)
      return
    }

    setLoading(true)
    try {
      const url = `${config.host}/funcionariosAtivos`
      const response = await fetchData(url, {
        tenantId,
        requisicao: 'calcularSaldoHoras',
        funcionarioId,
        dataInicio,
        dataFim
      })

      setDadosBancoHoras(response.detalhes || [])
      setAlertVariant('success')
      setAlertMessage('Dados carregados com sucesso!')
      setShowAlert(true)
    } catch (error) {
      console.error('Erro ao calcular banco de horas:', error)
      setAlertMessage('Erro ao calcular banco de horas.')
      setAlertVariant('danger')
      setShowAlert(true)
    } finally {
      setLoading(false)
    }
  }
  const abrirModalDetalhado = async funcionario => {
    console.log('funcionario', funcionario)
    setLoading(true) // Opcional: para exibir um loader no modal

    const dados = await fetchRelatorioDetalhado(
      funcionario,
      tenantId,
      RotaBase,
      null,
      dataInicio,
      dataFim
    )
    setDadosDetalhados(dados) // Armazena os dados detalhados
    setFuncionarioSelecionado(funcionario) // Define o funcionário selecionado
    setShowModal(true) // Abre o modal
    setLoading(false)
  }
  const fecharModal = () => {
    setShowModal(false)
    setFuncionarioSelecionado(null)
  }

  const exportarParaExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(dadosBancoHoras)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Banco de Horas')
    XLSX.writeFile(workbook, 'banco_de_horas.xlsx')
  }
  const exportarParaCsv = () => {
    const csv = dadosBancoHoras.map(row => Object.values(row).join(',')).join('\n')
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', 'banco_de_horas.csv')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  const exportarParaPdf = () => {
    const doc = new jsPDF()
    doc.text('Banco de Horas', 10, 10)

    const columns = [
      'Nome',
      'Cargo',
      'Setor',
      'Data',
      'Horas Trabalhadas',
      'Horas Previstas',
      'Saldo'
    ]
    console.log('dadosBancoHoras', dadosBancoHoras)
    const rows = dadosBancoHoras.map(
      ({ nome, cargo, setor, data, horasTrabalhadas, horasPrevistas, saldo }) => [
        nome || '--',
        cargo || '--',
        setor || '--',
        data || '--',
        horasTrabalhadas || '--:--',
        horasPrevistas || '--:--',
        saldo || '--:--'
      ]
    )

    doc.autoTable({ head: [columns], body: rows })
    doc.save('banco_de_horas.pdf')
  }

  return (
    <Container className="p-4">
      <h2>Banco de Horas</h2>
      <Form>
        <Form.Group>
          <Form.Label>Funcionário</Form.Label>
          <Form.Control
            as="select"
            value={funcionarioId}
            onChange={e => setFuncionarioId(e.target.value)}
          >
            <option value="">Todos os funcionários</option>
            {funcionarios.map(funcionario => (
              <option key={funcionario._id} value={funcionario._id}>
                {funcionario.Nome}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Data Início</Form.Label>
          <DatePicker
            selected={dataInicio}
            onChange={date => setDataInicio(date)}
            dateFormat="dd/MM/yyyy"
            className="form-control"
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Data Fim</Form.Label>
          <DatePicker
            selected={dataFim}
            onChange={date => setDataFim(date)}
            dateFormat="dd/MM/yyyy"
            className="form-control"
          />
        </Form.Group>
        <Button variant="primary" onClick={handleCalcularBancoHoras}>
          Calcular Banco de Horas
        </Button>
      </Form>

      {loading && <Spinner animation="border" className="mt-3" />}

      {showAlert && (
        <ShowAlertGeneric
          showAlert={showAlert}
          message={alertMessage}
          variant={alertVariant}
          setShowAlert={setShowAlert}
        />
      )}
      <div
        className="opcoes-container"
        onMouseEnter={() => setMostrarSubmenu(true)}
        onMouseLeave={() => setMostrarSubmenu(false)}
        style={{
          border: '1px solid #ddd',
          borderRadius: '8px',
          padding: '10px',
          textAlign: 'center',
          cursor: 'pointer',
          backgroundColor: '#f9f9f9',
          transition: 'box-shadow 0.3s',
          position: 'relative',
          width: '250px',
          alignSelf: 'flex-start'
        }}
      >
        Mais opções
        {mostrarSubmenu && (
          <div
            className="submenu"
            style={{
              display: 'block',
              position: 'absolute',
              top: '100%',
              left: '0',
              width: '250px',
              backgroundColor: '#fff',
              border: '1px solid #ddd',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              zIndex: 10,
              marginTop: '1px'
            }}
          >
            <div style={{ padding: '10px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <button
                onClick={exportarParaExcel}
                style={{
                  padding: '10px',
                  backgroundColor: '#007bff',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer'
                }}
              >
                Exportar para Excel
              </button>
              <button
                onClick={exportarParaCsv}
                style={{
                  padding: '10px',
                  backgroundColor: '#28a745',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer'
                }}
              >
                Exportar para CSV
              </button>
              <button
                onClick={exportarParaPdf}
                style={{
                  padding: '10px',
                  backgroundColor: '#dc3545',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer'
                }}
              >
                Exportar para PDF
              </button>
            </div>
          </div>
        )}
      </div>

      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th>Data</th>
            <th>Horas Trabalhadas</th>
            <th>Horas Previstas</th>
            <th>Saldo</th>
            <th style={{ width: '100px' }}>Detalhado</th>
          </tr>
        </thead>
        <tbody>
          {dadosBancoHoras.length > 0 ? (
            dadosBancoHoras.map((registro, index) => (
              <tr key={index}>
                <td>{registro.data}</td>
                <td>{registro.horasTrabalhadas}</td>
                <td>{registro.horasPrevistas}</td>
                <td>{registro.saldo}</td>
                <td>
                  <button
                    onClick={() => abrirModalDetalhado(funcionarioId)}
                    style={{
                      background: 'none',
                      border: 'none',
                      cursor: 'pointer',
                      padding: '5px 10px',
                      color: '#007bff', // Cor discreta para o texto
                      fontSize: '0.9em'
                    }}
                  >
                    Detalhado
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="text-center">
                Nenhum registro encontrado.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <ModalTabelaFuncionarios
        show={showModal}
        onHide={fecharModal}
        funcionario={dadosDetalhados[0]}
        dados={dadosDetalhados}
      />
    </Container>
  )
}

export default BancoDeHoras
