//notificacoes_relogioponto.jsx
import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../../../../context/AuthContext.jsx'
import { fetchGerentes } from '../GerenteOperacoes/gerenteOperacoes.jsx'
import { Container, Row, Col, Card, Form, Button, Spinner, Nav, Tab, Table } from 'react-bootstrap'
import { fetchData } from '../../../../backend/model.js'
import { ShowAlertGeneric } from '../../../../utils/form-utils.jsx'
import { config } from '../../../../backend/configConexaoGeral.js'
import { FaCheck } from 'react-icons/fa'
import { enviarEmail } from '../../../../backend/funcoes/emailService.js' // Importar o serviço de e-mail
import { enviarWhatsApp } from '../../../../backend/funcoes/whatsapp.js'
const NotificacoesRelogioPonto = () => {
  const { RotaBase, tenantId } = useContext(AuthContext)
  const [notificacoes, setNotificacoes] = useState([])
  const [loading, setLoading] = useState(false)
  const [filtros, setFiltros] = useState({
    status: 'pendente',
    tipo: '',
    dataInicio: '',
    dataFim: ''
  })
  const [alertMessage, setAlertMessage] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [alertVariant, setAlertVariant] = useState('success')

  // Estado para configurações de notificação
  const [configNotificacao, setConfigNotificacao] = useState({
    receberEmail: false,
    receberWhatsapp: false,
    monitoramentoAtrasos: false,
    enviarNotificacoesExtras: false
  })
  const [savingConfig, setSavingConfig] = useState(false)

  // Estado para controlar a aba ativa
  const [key, setKey] = useState('filtros')
  const [gerentes, setGerentes] = useState(null)

  useEffect(() => {
    fetchGerentes(RotaBase, tenantId, setGerentes)
  }, [RotaBase, tenantId]) // Executa a busca apenas quando essas variáveis mudam

  // Novo useEffect para processar `gerentes` após atualização
  useEffect(() => {
    if (gerentes && gerentes.length > 0) {
      setGerentes(gerentes[0].funcionarioId)
    }
  }, [gerentes]) // Esse efeito roda só quando `gerentes` for atualizado

  const fetchNotificacoes = async () => {
    setLoading(true)
    try {
      const url = `${config.host}/notificacaoRelogioponto`
      const dados = {
        requisicao: 'listarNotificacoes',
        tenantId,
        ...filtros
      }

      const response = await fetchData(url, dados)

      if (!response || response.length === 0) {
        setNotificacoes([])
      } else {
        setNotificacoes(response)
      }
    } catch (error) {
      console.error('Erro ao buscar notificações:', error)
      setAlertMessage('Erro ao carregar notificações.')
      setAlertVariant('danger')
      setShowAlert(true)
    } finally {
      setLoading(false)
    }
  }

  // Função para buscar configurações de notificação e informações do gestor
  const fetchConfigNotificacao = async () => {
    try {
      const url = `${config.host}/configuracoesGerais`
      const dados = {
        requisicao: 'obterConfiguracoesNotificacoes',
        rota_base: RotaBase,
        tenantId
      }

      const response = await fetchData(url, dados)

      if (response.success) {
        setConfigNotificacao({
          receberEmail: response.receberEmail,
          receberWhatsapp: response.receberWhatsapp,
          monitoramentoAtrasos: response.monitoramentoAtrasos || false,
          enviarNotificacoesExtras: response.enviarNotificacoesExtras || false
        })

        // Buscar gerentes apenas se receberEmail ou receberWhatsapp estiver ativado
        if (response.receberEmail || response.receberWhatsapp) {
          fetchGerentes(RotaBase, tenantId, setGerentes)
        }
      }
    } catch (error) {
      console.error('Erro ao buscar configurações de notificação:', error)
    }
  }

  useEffect(() => {
    if (filtros) {
      fetchNotificacoes()
    }
  }, [filtros])

  useEffect(() => {
    fetchConfigNotificacao()
  }, [])

  const handleFiltroChange = e => {
    const { name, value } = e.target
    setFiltros(prev => ({ ...prev, [name]: value }))
  }

  const resolverNotificacao = async id => {
    try {
      const url = `${config.host}/notificacaoRelogioponto`
      const dados = {
        requisicao: 'atualizarStatusNotificacao',
        id,
        status: 'resolvida'
      }

      const notificacaoAtualizada = await fetchData(url, dados)
      console.log('Notificação atualizada:', notificacaoAtualizada.notificacao)

      setAlertMessage('Notificação resolvida com sucesso!')
      setAlertVariant('success')
      setShowAlert(true)
      fetchNotificacoes()

      // Garantir que há configuração para envio de e-mails
      if (configNotificacao.receberEmail && notificacaoAtualizada.notificacao) {
        const { funcionarioId, tipo, tipoAtraso, tempoDeAtraso, tempoAntecipado, data } =
          notificacaoAtualizada.notificacao
        let dataFormatada = new Date(data)
        // extraia a hora da data
        const horario = `${String(dataFormatada.getUTCHours()).padStart(2, '0')}:${String(
          dataFormatada.getUTCMinutes()
        ).padStart(2, '0')}`
        dataFormatada = dataFormatada.toLocaleDateString('pt-BR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        })

        const emailContent = `
                <h2>Notificação Resolvida</h2>
                <p><strong>Funcionário:</strong> ${funcionarioId?.Nome || 'N/A'}</p>
                <p><strong>Tipo:</strong> ${tipo}</p>
                <p><strong>Detalhe:</strong> ${tipoAtraso}</p>
                <p><strong>Minutos:</strong> ${
                  tempoDeAtraso > 0 ? `${tempoDeAtraso} min` : `${tempoAntecipado} min`
                }</p>
                <p><strong>Data:</strong> ${dataFormatada}</p>
                <p><strong>Horário:</strong> ${horario}</p>
                <hr />
            `

        await enviarEmail({
          to: gerentes.Email,
          subject: 'Notificação Resolvida - Relógio de Ponto',
          message: emailContent,
          sistema: 'moda.negocios360'
        })

        setAlertMessage('Notificação enviada por e-mail ao gestor.')
        setAlertVariant('success')
        setShowAlert(true)
      }
    } catch (error) {
      console.error('Erro ao resolver notificação:', error)
      setAlertMessage('Erro ao resolver notificação.')
      setAlertVariant('danger')
      setShowAlert(true)
    }
  }

  const handleConfigChange = async e => {
    const { name, checked } = e.target

    // Atualiza o estado do checkbox imediatamente para uma resposta mais rápida ao usuário
    setConfigNotificacao(prev => ({
      ...prev,
      [name]: checked
    }))

    try {
      const url = `${config.host}/configuracoesGerais`

      await fetchData(url, {
        requisicao:
          name === 'receberEmail'
            ? 'atualizarReceberEmail'
            : name === 'receberWhatsapp'
            ? 'atualizarReceberWhatsapp'
            : name === 'monitoramentoAtrasos'
            ? 'atualizarMonitoramentoAtrasos'
            : 'atualizarEnviarNotificacoesExtras',
        rota_base: RotaBase,
        tenantId,
        [name]: checked
      })

      setAlertMessage(
        `Configuração de ${name === 'receberEmail' ? 'e-mail' : 'WhatsApp'} atualizada!`
      )
      setAlertVariant('success')
      setShowAlert(true)

      // Caso tenha sido ativado, garantir que os gerentes sejam buscados
      if (checked) {
        fetchGerentes(RotaBase, tenantId, setGerentes)
      }
    } catch (error) {
      console.error('Erro ao salvar configuração:', error)
      setAlertMessage('Erro ao salvar configuração.')
      setAlertVariant('danger')
      setShowAlert(true)
    }
  }

  return (
    <Container className="p-4">
      <h2 className="mb-4">Notificações do Relógio de Ponto</h2>

      {showAlert && (
        <ShowAlertGeneric
          showAlert={showAlert}
          message={alertMessage}
          variant={alertVariant}
          setShowAlert={setShowAlert}
          timeOut={4000}
        />
      )}

      {/* Abas para alternar entre Filtros e Configurações */}
      <Row>
        <Col md={12}>
          <Nav variant="tabs" activeKey={key} onSelect={k => setKey(k)}>
            <Nav.Item>
              <Nav.Link eventKey="filtros">Filtros</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="configuracoes">Configurações de Notificação</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
      </Row>

      {/* Conteúdo das abas sem repetição de títulos */}
      <Row className="mt-3">
        <Col md={12}>
          <Tab.Content>
            {/* Aba de Filtros */}
            <Tab.Pane eventKey="filtros" active={key === 'filtros'}>
              <Form>
                <Row className="g-3">
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        name="status"
                        value={filtros.status}
                        onChange={handleFiltroChange}
                      >
                        <option value="">Todos</option>
                        <option value="pendente">Pendente</option>
                        <option value="resolvida">Resolvida</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label>Tipo</Form.Label>
                      <Form.Control
                        as="select"
                        name="tipo"
                        value={filtros.tipo}
                        onChange={handleFiltroChange}
                      >
                        <option value="">Todos</option>
                        <option value="falta">Falta</option>
                        <option value="atraso">Atraso</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label>Data Início</Form.Label>
                      <Form.Control
                        type="date"
                        name="dataInicio"
                        value={filtros.dataInicio}
                        onChange={handleFiltroChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label>Data Fim</Form.Label>
                      <Form.Control
                        type="date"
                        name="dataFim"
                        value={filtros.dataFim}
                        onChange={handleFiltroChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Tab.Pane>

            {/* Aba de Configurações de Notificação */}
            <Tab.Pane eventKey="configuracoes" active={key === 'configuracoes'}>
              <Row className="g-3">
                <Col md={6}>
                  <Form.Group controlId="receberEmail">
                    <Form.Check
                      type="checkbox"
                      label="Receber notificações por E-mail"
                      name="receberEmail"
                      checked={configNotificacao.receberEmail}
                      onChange={handleConfigChange} // Agora salva automaticamente
                    />
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group controlId="receberWhatsapp">
                    <Form.Check
                      type="checkbox"
                      label="Receber notificações por WhatsApp"
                      name="receberWhatsapp"
                      checked={configNotificacao.receberWhatsapp}
                      onChange={handleConfigChange} // Agora salva automaticamente
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="monitoramentoAtrasos">
                    <Form.Check
                      type="checkbox"
                      label="Ativar Monitoramento de Atrasos em Períodos"
                      name="monitoramentoAtrasos"
                      checked={configNotificacao.monitoramentoAtrasos}
                      onChange={handleConfigChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="enviarNotificacoesExtras">
                    <Form.Check
                      type="checkbox"
                      label="Criar e Enviar Notificações Extras para o Gerente"
                      name="enviarNotificacoesExtras"
                      checked={configNotificacao.enviarNotificacoesExtras}
                      onChange={handleConfigChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* Exibição das informações do gerente caso um dos checkboxes esteja ativado */}
              {(configNotificacao.receberEmail || configNotificacao.receberWhatsapp) &&
                gerentes && (
                  <Row className="g-3 mt-2">
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Nome do Gestor</Form.Label>
                        <Form.Text className="d-block">
                          {gerentes.Nome || 'Nome não disponível'}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                    {configNotificacao.receberEmail && (
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Email do Gestor</Form.Label>
                          <Form.Text className="d-block">
                            {gerentes.Email || 'E-mail não informado'}
                          </Form.Text>
                        </Form.Group>
                      </Col>
                    )}
                    {configNotificacao.receberWhatsapp && (
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>WhatsApp do Gestor</Form.Label>
                          <Form.Text className="d-block">
                            {gerentes.Tel || 'WhatsApp não informado'}
                          </Form.Text>
                        </Form.Group>
                      </Col>
                    )}
                  </Row>
                )}

              {/* Botão para enviar e-mail */}
              <Row>
                <Col md={12} className="mt-3">
                  <div className="d-flex">
                    <Button
                      variant="primary"
                      className="me-2"
                      onClick={() => {
                        if (!gerentes || !gerentes.Email) {
                          setAlertMessage('Nenhum e-mail de gerente disponível para enviar.')
                          setAlertVariant('warning')
                          setShowAlert(true)
                          return
                        }

                        if (!notificacoes || notificacoes.length === 0) {
                          setAlertMessage('Nenhuma notificação para enviar.')
                          setAlertVariant('warning')
                          setShowAlert(true)
                          return
                        }

                        // Construindo a mensagem com base nas notificações da tabela
                        const messageContent = notificacoes
                          .map(
                            notif => `
                <p><strong>Funcionário:</strong> ${notif.funcionarioId?.Nome || 'N/A'}</p>
                <p><strong>Tipo:</strong> ${notif.tipo === 'atraso' ? 'Atrasou' : 'Adiantou'}</p>
                <p><strong>Minutos:</strong> ${
                  notif.tempoDeAtraso > 0
                    ? `${notif.tempoDeAtraso} min`
                    : `${notif.tempoAntecipado} min`
                }</p>
                <p><strong>Situaçao:</strong> ${notif.tipoAtraso}</p>
                <p><strong>Data:</strong> ${new Date(notif.data).toLocaleDateString()}</p>
                <hr />
              `
                          )
                          .join('')

                        enviarEmail({
                          to: gerentes.Email,
                          subject: 'Relatório de Notificações - Relógio de Ponto',
                          message: `
              <p>Olá ${gerentes.Nome || 'Gestor'},</p>
              <p>Segue o relatório das notificações do relógio de ponto:</p>
              ${messageContent}
              <p>Atenciosamente,<br>Equipe Relogio de Ponto</p>
            `,
                          sistema: 'moda.negocios360'
                        })

                        setAlertMessage('E-mail de notificação enviado com sucesso!')
                        setAlertVariant('success')
                        setShowAlert(true)
                      }}
                    >
                      Enviar Notificação por E-mail
                    </Button>

                    <Button
                      variant="success"
                      onClick={() => {
                        // Verifica se o número do gerente está disponível
                        if (!gerentes || !gerentes.Tel) {
                          setAlertMessage(
                            'Nenhum número de telefone de gerente disponível para enviar via WhatsApp.'
                          )
                          setAlertVariant('warning')
                          setShowAlert(true)
                          return
                        }

                        // Verifica se há notificações para enviar
                        if (!notificacoes || notificacoes.length === 0) {
                          setAlertMessage('Nenhuma notificação para enviar.')
                          setAlertVariant('warning')
                          setShowAlert(true)
                          return
                        }

                        // Constrói a mensagem com base nas notificações
                        const messageContent = notificacoes
                          .map(
                            notif => `
                <p><strong>Funcionário:</strong> ${notif.funcionarioId?.Nome || 'N/A'}</p>
                <p><strong>Tipo:</strong> ${notif.tipo === 'atraso' ? 'Atrasou' : 'Adiantou'}</p>
                <p><strong>Minutos:</strong> ${
                  notif.tempoDeAtraso > 0
                    ? `${notif.tempoDeAtraso} min`
                    : `${notif.tempoAntecipado} min`
                }</p>
                <p><strong>Situação:</strong> ${notif.tipoAtraso}</p>
                <p><strong>Data:</strong> ${new Date(notif.data).toLocaleDateString()}</p>
                <hr />
              `
                          )
                          .join('')

                        // Chama a função para enviar a mensagem via WhatsApp
                        enviarWhatsApp({
                          to: gerentes.Tel,
                          message: `
              <p>Olá ${gerentes.Nome || 'Gestor'},</p>
              <p>Segue o relatório das notificações do relógio de ponto via WhatsApp:</p>
              ${messageContent}
              <p>Atenciosamente,<br>Equipe Relogio de Ponto</p>
            `,
                          sistema: 'moda.negocios360'
                        })
                          .then(() => {
                            setAlertMessage('Mensagem via WhatsApp enviada com sucesso!')
                            setAlertVariant('success')
                            setShowAlert(true)
                          })
                          .catch(error => {
                            setAlertMessage(
                              'Erro ao enviar mensagem via WhatsApp: ' + error.message
                            )
                            setAlertVariant('danger')
                            setShowAlert(true)
                          })
                      }}
                    >
                      Enviar Notificação via WhatsApp
                    </Button>
                  </div>
                </Col>
              </Row>
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>

      {/* Spinner de Carregamento */}
      {loading && (
        <div className="d-flex justify-content-center my-4">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Carregando...</span>
          </Spinner>
        </div>
      )}

      {/* Lista de Notificações */}
      <Card className="mt-4">
        <Card.Header>Lista de Notificações</Card.Header>
        <Card.Body className="p-0">
          <Table striped bordered hover responsive className="mb-0">
            <thead className="table-dark">
              <tr>
                <th>Funcionário</th>
                <th>Tipo</th>
                <th>Minutos</th>
                <th>Situação</th>
                <th>Data</th>
                <th>Status</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {notificacoes.length === 0 && !loading ? (
                <tr>
                  <td colSpan="7" className="text-center">
                    Nenhuma notificação encontrada.
                  </td>
                </tr>
              ) : (
                notificacoes.map(notificacao => (
                  <tr key={notificacao._id}>
                    <td>{notificacao.funcionarioId?.Nome || 'N/A'}</td>
                    <td>
                      {notificacao.tipo
                        .replace('atraso', 'Atrasou')
                        .replace('antecipacao', 'Antecipou')}
                    </td>
                    <td>
                      {notificacao.tempoDeAtraso > 0
                        ? `${notificacao.tempoDeAtraso} min`
                        : `${notificacao.tempoAntecipado} min`}
                    </td>
                    <td>{notificacao.tipoAtraso}</td>
                    <td>{new Date(notificacao.data).toLocaleDateString()}</td>
                    <td>
                      <span
                        className={
                          notificacao.status === 'pendente'
                            ? 'badge bg-warning text-dark'
                            : 'badge bg-success'
                        }
                      >
                        {notificacao.status.charAt(0).toUpperCase() + notificacao.status.slice(1)}
                      </span>
                    </td>
                    <td>
                      {notificacao.status === 'pendente' && (
                        <Button
                          variant="success"
                          size="sm"
                          onClick={() => resolverNotificacao(notificacao._id)}
                          title="Resolver Notificação"
                        >
                          <FaCheck /> Resolver
                        </Button>
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Container>
  )
}

export default NotificacoesRelogioPonto
