import { NavDropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import PrivateMenuItem from '../../../src/components/PrivateMenuItem.jsx'

const RelogioDePontoMenu = () => {
  return (
    <PrivateMenuItem tela="relogiodeponto">
      <NavDropdown title="Relógio de Ponto" id="relogiodeponto-dropdown">
        <PrivateMenuItem tela="Gerenciamento de Presença">
          <NavDropdown.Item as={Link} to="/funcionarios-ativos" className="nav-dropdown-item">
            Bater Ponto
          </NavDropdown.Item>
        </PrivateMenuItem>
        {/* Submenu Cadastro */}
        <NavDropdown
          title="Cadastro"
          id="cadastro-submenu"
          drop="end"
          className="custom-dropdown-item"
        >
          <PrivateMenuItem tela="Cadastro Justificativas de Faltas">
            <NavDropdown.Item as={Link} to="/justificativas-faltas" className="nav-dropdown-item">
              Justificativas de Faltas
            </NavDropdown.Item>
          </PrivateMenuItem>
          <PrivateMenuItem tela="Cadastro Cliente relogiodeponto">
            <NavDropdown.Item
              as={Link}
              to="/relogiodeponto-cadastro-cliente"
              className="nav-dropdown-item"
            >
              Cadastrar Colaborador
            </NavDropdown.Item>
          </PrivateMenuItem>
          <PrivateMenuItem tela="Cadastro Facial relogiodeponto">
            <NavDropdown.Item as={Link} to="/cadastro-facial" className="nav-dropdown-item">
              Cadastro Facial
            </NavDropdown.Item>
          </PrivateMenuItem>
        </NavDropdown>

        {/* Submenu Gerenciamento */}
        <NavDropdown
          title="Gerenciamento"
          id="gerenciamento-submenu"
          drop="end"
          className="custom-dropdown-item"
        >
          <PrivateMenuItem tela="Edição Cliente relogiodeponto">
            <NavDropdown.Item
              as={Link}
              to="/relogiodeponto-pesquisa-e-edicao-cliente"
              className="nav-dropdown-item"
            >
              Pesquisa e Edição de Colaborador
            </NavDropdown.Item>
          </PrivateMenuItem>
          <PrivateMenuItem tela="Gerenciamento de Setores">
            <NavDropdown.Item as={Link} to="/gerenciamento-setores" className="nav-dropdown-item">
              Setores
            </NavDropdown.Item>
          </PrivateMenuItem>
          <PrivateMenuItem tela="Gerenciamento de Cargos">
            <NavDropdown.Item as={Link} to="/gerenciamento-cargos" className="nav-dropdown-item">
              Cargos
            </NavDropdown.Item>
          </PrivateMenuItem>
          <PrivateMenuItem tela="Gerenciamento de Horários">
            <NavDropdown.Item as={Link} to="/gerenciamento-horarios" className="nav-dropdown-item">
              Horários
            </NavDropdown.Item>
          </PrivateMenuItem>
          <PrivateMenuItem tela="Gerenciamento de Gerentes de Operações">
            <NavDropdown.Item as={Link} to="/gerente-operacoes" className="nav-dropdown-item">
              Gerentes de Operações
            </NavDropdown.Item>
          </PrivateMenuItem>
        </NavDropdown>

        {/* Submenu Relatórios */}
        <NavDropdown
          title="Relatórios"
          id="relatorios-submenu"
          drop="end"
          className="custom-dropdown-item"
        >
          <PrivateMenuItem tela="Relatórios de Frequência">
            <NavDropdown.Item as={Link} to="/relatorio-frequencia" className="nav-dropdown-item">
              Frequência
            </NavDropdown.Item>
          </PrivateMenuItem>
          <PrivateMenuItem tela="Banco de horas">
            <NavDropdown.Item as={Link} to="/banco-de-horas" className="nav-dropdown-item">
              Banco de horas
            </NavDropdown.Item>
          </PrivateMenuItem>
          <PrivateMenuItem tela="Relatórios de Atrasos e Faltas">
            <NavDropdown.Item
              as={Link}
              to="/relatorios-atrasos-faltas"
              className="nav-dropdown-item"
            >
              Atrasos e Faltas
            </NavDropdown.Item>
          </PrivateMenuItem>
          <PrivateMenuItem tela="Relatórios por Setor">
            <NavDropdown.Item as={Link} to="/relatorios-setor" className="nav-dropdown-item">
              Por Setor
            </NavDropdown.Item>
          </PrivateMenuItem>
          <PrivateMenuItem tela="Histórico Individual">
            <NavDropdown.Item as={Link} to="/historico-individual" className="nav-dropdown-item">
              Histórico Individual
            </NavDropdown.Item>
          </PrivateMenuItem>
        </NavDropdown>

        {/* Submenu Configurações */}
        <NavDropdown
          title="Configurações"
          id="configuracoes-submenu"
          drop="end"
          className="custom-dropdown-item"
        >
          <PrivateMenuItem tela="Configurações de Regras">
            <NavDropdown.Item as={Link} to="/regras-ponto" className="nav-dropdown-item">
              Regras de Ponto
            </NavDropdown.Item>
          </PrivateMenuItem>
          <PrivateMenuItem tela="Regras de Pontualidade">
            <NavDropdown.Item as={Link} to="/regras-pontualidade" className="nav-dropdown-item">
              Regras de Pontualidade
            </NavDropdown.Item>
          </PrivateMenuItem>
        </NavDropdown>

        {/* Submenu Alertas e Revisões */}
        <NavDropdown
          title="Alertas e Revisões"
          id="alertas-revisoes-submenu"
          drop="end"
          className="custom-dropdown-item"
        >
          <PrivateMenuItem tela="Notificacoes relogiodeponto">
            <NavDropdown.Item
              as={Link}
              to="/notificacoes-relogiodeponto"
              className="nav-dropdown-item"
            >
              Notificações
            </NavDropdown.Item>
          </PrivateMenuItem>
          <PrivateMenuItem tela="Revisão de Pontos relogiodeponto">
            <NavDropdown.Item as={Link} to="/revisao-pontos" className="nav-dropdown-item">
              Revisão de Pontos
            </NavDropdown.Item>
          </PrivateMenuItem>
        </NavDropdown>
      </NavDropdown>
    </PrivateMenuItem>
  )
}

export default RelogioDePontoMenu
