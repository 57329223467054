import { useState, useEffect, useContext } from 'react'
import { Form, Button, Container, Row, Col, Tab, Nav } from 'react-bootstrap'
import { fetchData } from '../../../../backend/model.js'
import { config } from '../../../../backend/configConexaoGeral'
import { AuthContext } from '../../../../context/AuthContext.jsx'
import { ShowAlertGeneric } from '../../../../utils/form-utils.jsx'
import PropTypes from 'prop-types'
const RegrasPontualidade = () => {
  const { tenantId } = useContext(AuthContext)

  // Estados para os cargos e seleção
  const [cargos, setCargos] = useState([])
  const [cargoSelecionado, setCargoSelecionado] = useState('')

  // Estados para Atrasos
  const [minutosCafeManhaAtraso, setMinutosCafeManhaAtraso] = useState('')
  const [minutosAlmocoAtraso, setMinutosAlmocoAtraso] = useState('')
  const [minutosCafeTardeAtraso, setMinutosCafeTardeAtraso] = useState('')
  const [minutosEntradaAtraso, setMinutosEntradaAtraso] = useState('')
  const [minutosSaidaAtraso, setMinutosSaidaAtraso] = useState('')

  // Estados para Adiantamentos
  const [minutosCafeManhaAdiantamento, setMinutosCafeManhaAdiantamento] = useState('')
  const [minutosAlmocoAdiantamento, setMinutosAlmocoAdiantamento] = useState('')
  const [minutosCafeTardeAdiantamento, setMinutosCafeTardeAdiantamento] = useState('')
  const [minutosEntradaAdiantamento, setMinutosEntradaAdiantamento] = useState('')
  const [minutosSaidaAdiantamento, setMinutosSaidaAdiantamento] = useState('')

  // Estados para Notificações Extras

  const [numeroOcorrenciasAtraso, setNumeroOcorrenciasAtraso] = useState('')
  const [periodoDiasAtraso, setPeriodoDiasAtraso] = useState('')
  const [numeroOcorrenciasAdiantamento, setNumeroOcorrenciasAdiantamento] = useState('')
  const [periodoDiasAdiantamento, setPeriodoDiasAdiantamento] = useState('')

  // Estados para Alertas
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertVariant, setAlertVariant] = useState('')

  // Estados para monitoramento de atrasos em períodos específicos
  const [minutosAtrasoEntrada, setMinutosAtrasoEntrada] = useState(0)
  const [minutosAtrasoAlmoco, setMinutosAtrasoAlmoco] = useState(0)
  const [minutosAtrasoCafeManha, setMinutosAtrasoCafeManha] = useState(0)
  const [minutosAtrasoCafeTarde, setMinutosAtrasoCafeTarde] = useState(0)
  const [minutosAtrasoSaida, setMinutosAtrasoSaida] = useState(0)

  // Estado para Controle das Abas
  const [key, setKey] = useState('atrasos')
  // Fetch dos cargos quando o componente monta
  useEffect(() => {
    const fetchCargos = async () => {
      try {
        const url = `${config.host}/gerenciarCargos`
        const response = await fetchData(url, { tenantId, requisicao: 'listarTodosOsCargos' })
        setCargos(response.cargos || [])
      } catch (error) {
        console.error('Erro ao buscar cargos:', error)
      }
    }
    fetchCargos()
  }, [tenantId])

  // useEffect para buscar regras quando um cargo é selecionado
  useEffect(() => {
    const fetchRegras = async () => {
      if (!cargoSelecionado) {
        // Se nenhum cargo estiver selecionado, limpar os campos
        resetCampos()
        return
      }

      try {
        const url = `${config.host}/regrasPontualidade` // Utilizar a rota correta
        const dados = {
          tenantId,
          cargoId: cargoSelecionado,
          requisicao: 'listarRegrasPontualidade' // Incluir a requisição correta
        }

        const response = await fetchData(url, dados)

        if (response.regras && response.regras.length > 0) {
          const regra = response.regras[0] // Assumindo que há apenas uma regra por cargo

          // Atualizar os estados com os valores da regra existente
          setMinutosEntradaAtraso(regra.minutosEntradaAtraso || '')
          setMinutosCafeManhaAtraso(regra.minutosCafeManhaAtraso || '')
          setMinutosAlmocoAtraso(regra.minutosAlmocoAtraso || '')
          setMinutosCafeTardeAtraso(regra.minutosCafeTardeAtraso || '')
          setMinutosSaidaAtraso(regra.minutosSaidaAtraso || '')

          setMinutosEntradaAdiantamento(regra.minutosEntradaAdiantamento || '')
          setMinutosCafeManhaAdiantamento(regra.minutosCafeManhaAdiantamento || '')
          setMinutosAlmocoAdiantamento(regra.minutosAlmocoAdiantamento || '')
          setMinutosCafeTardeAdiantamento(regra.minutosCafeTardeAdiantamento || '')
          setMinutosSaidaAdiantamento(regra.minutosSaidaAdiantamento || '')

          setNumeroOcorrenciasAtraso(regra.numeroOcorrenciasAtraso || '')
          setPeriodoDiasAtraso(regra.periodoDiasAtraso || '')
          setNumeroOcorrenciasAdiantamento(regra.numeroOcorrenciasAdiantamento || '')
          setPeriodoDiasAdiantamento(regra.periodoDiasAdiantamento || '')

          setMinutosAtrasoEntrada(regra.minutosAtrasoEntrada || 0)
          setMinutosAtrasoAlmoco(regra.minutosAtrasoAlmoco || 0)
          setMinutosAtrasoCafeManha(regra.minutosAtrasoCafeManha || 0)
          setMinutosAtrasoCafeTarde(regra.minutosAtrasoCafeTarde || 0)
          setMinutosAtrasoSaida(regra.minutosAtrasoSaida || 0)
        } else {
          // Se não houver regras, resetar os campos
          resetCampos()
        }
      } catch (error) {
        console.error('Erro ao buscar regras de pontualidade:', error)
        setAlertMessage('Erro ao buscar regras de pontualidade. Tente novamente.')
        setAlertVariant('danger')
        setShowAlert(true)
      }
    }

    fetchRegras()
  }, [cargoSelecionado, tenantId])

  // Função para resetar os campos
  const resetCampos = () => {
    setMinutosEntradaAtraso('')
    setMinutosCafeManhaAtraso('')
    setMinutosAlmocoAtraso('')
    setMinutosCafeTardeAtraso('')
    setMinutosSaidaAtraso('')
    setMinutosEntradaAdiantamento('')
    setMinutosCafeManhaAdiantamento('')
    setMinutosAlmocoAdiantamento('')
    setMinutosCafeTardeAdiantamento('')
    setMinutosSaidaAdiantamento('')

    setNumeroOcorrenciasAtraso('')
    setPeriodoDiasAtraso('')
    setNumeroOcorrenciasAdiantamento('')
    setPeriodoDiasAdiantamento('')
  }
  // Função para lidar com o envio do formulário
  const handleSubmit = async e => {
    e.preventDefault() // Prevenir o comportamento padrão do formulário
    // Lista de todos os campos que devem ser verificados
    const campos = [
      minutosEntradaAtraso,
      minutosCafeManhaAtraso,
      minutosAlmocoAtraso,
      minutosCafeTardeAtraso,
      minutosSaidaAtraso,
      minutosEntradaAdiantamento,
      minutosCafeManhaAdiantamento,
      minutosAlmocoAdiantamento,
      minutosCafeTardeAdiantamento,
      minutosSaidaAdiantamento
    ]

    // Verificar se pelo menos um campo está preenchido
    const peloMenosUmPreenchido = campos.some(campo => campo !== '' && campo !== null)

    if (!peloMenosUmPreenchido) {
      setAlertMessage('Por favor, preencha pelo menos um dos campos de atraso ou adiantamento.')
      setAlertVariant('warning')
      setShowAlert(true)
      return // Interromper a execução da função
    }

    try {
      const url = `${config.host}/regrasPontualidade`
      const dados = {
        requisicao: 'criarRegrasPontualidade',
        cargoId: cargoSelecionado,
        // Atrasos
        minutosCafeManhaAtraso: Number(minutosCafeManhaAtraso),
        minutosAlmocoAtraso: Number(minutosAlmocoAtraso),
        minutosCafeTardeAtraso: Number(minutosCafeTardeAtraso),
        minutosEntradaAtraso: Number(minutosEntradaAtraso),
        minutosSaidaAtraso: Number(minutosSaidaAtraso),
        // Adiantamentos
        minutosCafeManhaAdiantamento: Number(minutosCafeManhaAdiantamento),
        minutosAlmocoAdiantamento: Number(minutosAlmocoAdiantamento),
        minutosCafeTardeAdiantamento: Number(minutosCafeTardeAdiantamento),
        minutosEntradaAdiantamento: Number(minutosEntradaAdiantamento),
        minutosSaidaAdiantamento: Number(minutosSaidaAdiantamento),
        // Notificações Extras

        numeroOcorrenciasAtraso: Number(numeroOcorrenciasAtraso),
        periodoDiasAtraso: Number(periodoDiasAtraso),
        numeroOcorrenciasAdiantamento: Number(numeroOcorrenciasAdiantamento),
        periodoDiasAdiantamento: Number(periodoDiasAdiantamento),
        tenantId,

        minutosAtrasoEntrada: Number(minutosAtrasoEntrada),
        minutosAtrasoAlmoco: Number(minutosAtrasoAlmoco),
        minutosAtrasoCafeManha: Number(minutosAtrasoCafeManha),
        minutosAtrasoCafeTarde: Number(minutosAtrasoCafeTarde),
        minutosAtrasoSaida: Number(minutosAtrasoSaida)
      }

      await fetchData(url, dados)

      setAlertMessage('Regras de pontualidade cadastradas com sucesso!')
      setAlertVariant('success')
      setShowAlert(true)

      // Resetar campos
      setCargoSelecionado('')
      setMinutosCafeManhaAtraso('')
      setMinutosAlmocoAtraso('')
      setMinutosCafeTardeAtraso('')
      setMinutosEntradaAtraso('')
      setMinutosSaidaAtraso('')
      setMinutosCafeManhaAdiantamento('')
      setMinutosAlmocoAdiantamento('')
      setMinutosCafeTardeAdiantamento('')
      setMinutosEntradaAdiantamento('')
      setMinutosSaidaAdiantamento('')

      setNumeroOcorrenciasAtraso('')
      setPeriodoDiasAtraso('')
      setNumeroOcorrenciasAdiantamento('')
      setPeriodoDiasAdiantamento('')
    } catch (error) {
      setAlertMessage('Erro ao cadastrar regras de pontualidade. Tente novamente.')
      setAlertVariant('danger')
      setShowAlert(true)
      console.error('Erro ao criar regras de pontualidade:', error)
    }
  }

  return (
    <Container>
      <h2>Cadastro de Regras de Pontualidade</h2>
      {showAlert && (
        <ShowAlertGeneric
          showAlert={showAlert}
          message={alertMessage}
          variant={alertVariant}
          setShowAlert={setShowAlert}
          timeOut={4000}
        />
      )}

      <Row>
        {/* Limites para Registro de Notificações – conteúdo restaurado conforme Bloco 2 */}
        <Col
          md={4}
          className="me-5"
          style={{
            border: '1px solid #ccc',
            padding: '10px',
            // marginRight: '30px',
            borderRadius: '8px'
          }}
        >
          <h5 style={{ marginBottom: '10px' }}>Limites para Registro de Notificações</h5>
          <p style={{ fontSize: '14px', color: '#666' }}>
            Os limites configurados abaixo serão usados para determinar se uma notificação será
            registrada. Por exemplo, se o tempo limite do café da manhã for definido como 3 minutos
            e o funcionário ultrapassar esse tempo no registro do ponto, será registrada uma
            notificação de atraso.
          </p>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Cargo</Form.Label>
              <Form.Control
                as="select"
                value={cargoSelecionado}
                onChange={e => setCargoSelecionado(e.target.value)}
              >
                <option value="">Selecione um cargo</option>
                {cargos.map(cargo => (
                  <option key={cargo._id} value={cargo._id}>
                    {cargo.nome}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            {/* Abas lado a lado */}
            <Row className="mb-3 align-items-center justify-content-between">
              <Nav variant="tabs" className="d-flex w-100 justify-content-between">
                <Nav.Item>
                  <Nav.Link
                    eventKey="atrasos"
                    active={key === 'atrasos'}
                    onClick={() => setKey('atrasos')}
                  >
                    Atrasos
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="adiantamentos"
                    active={key === 'adiantamentos'}
                    onClick={() => setKey('adiantamentos')}
                  >
                    Adiantamentos
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Row>

            <Tab.Content>
              {/* Aba de Atrasos */}
              <Tab.Pane eventKey="atrasos" active={key === 'atrasos'}>
                <div
                  style={{
                    maxHeight: '300px',
                    overflowY: 'auto',
                    paddingRight: '15px'
                  }}
                >
                  <h4 className="mb-4 text-primary">Configuração de Atrasos</h4>
                  <Row>
                    <Col md={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>Limite de Minutos para Entrada na Empresa</Form.Label>
                        <Form.Control
                          type="number"
                          value={minutosEntradaAtraso}
                          onChange={e => setMinutosEntradaAtraso(e.target.value)}
                          className="w-50"
                          min="0"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Limite de Minutos para retorno do Café da Manhã</Form.Label>
                        <Form.Control
                          type="number"
                          value={minutosCafeManhaAtraso}
                          onChange={e => setMinutosCafeManhaAtraso(e.target.value)}
                          className="w-50"
                          min="0"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Limite de Minutos para retorno do Almoço</Form.Label>
                        <Form.Control
                          type="number"
                          value={minutosAlmocoAtraso}
                          onChange={e => setMinutosAlmocoAtraso(e.target.value)}
                          className="w-50"
                          min="0"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Limite de Minutos para retorno do Café da Tarde</Form.Label>
                        <Form.Control
                          type="number"
                          value={minutosCafeTardeAtraso}
                          onChange={e => setMinutosCafeTardeAtraso(e.target.value)}
                          className="w-50"
                          min="0"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Limite de Minutos para Saída da Empresa</Form.Label>
                        <Form.Control
                          type="number"
                          value={minutosSaidaAtraso}
                          onChange={e => setMinutosSaidaAtraso(e.target.value)}
                          className="w-50"
                          min="0"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </Tab.Pane>

              {/* Aba de Adiantamentos */}
              <Tab.Pane eventKey="adiantamentos" active={key === 'adiantamentos'}>
                <div
                  style={{
                    maxHeight: '300px',
                    overflowY: 'auto',
                    paddingRight: '15px'
                  }}
                >
                  <h4 className="mb-4 text-primary">Configuração de Adiantamentos</h4>
                  <Row>
                    <Col md={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>Limite de Minutos p/ Entrada na Empresa</Form.Label>
                        <Form.Control
                          type="number"
                          value={minutosEntradaAdiantamento}
                          onChange={e => setMinutosEntradaAdiantamento(e.target.value)}
                          className="w-50"
                          min="0"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Limite de Minutos para Café da Manhã</Form.Label>
                        <Form.Control
                          type="number"
                          value={minutosCafeManhaAdiantamento}
                          onChange={e => setMinutosCafeManhaAdiantamento(e.target.value)}
                          className="w-50"
                          min="0"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Limite de Minutos para Almoço</Form.Label>
                        <Form.Control
                          type="number"
                          value={minutosAlmocoAdiantamento}
                          onChange={e => setMinutosAlmocoAdiantamento(e.target.value)}
                          className="w-50"
                          min="0"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Limite de Minutos para Café da Tarde</Form.Label>
                        <Form.Control
                          type="number"
                          value={minutosCafeTardeAdiantamento}
                          onChange={e => setMinutosCafeTardeAdiantamento(e.target.value)}
                          className="w-50"
                          min="0"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Limite de Minutos para Saída da Empresa</Form.Label>
                        <Form.Control
                          type="number"
                          value={minutosSaidaAdiantamento}
                          onChange={e => setMinutosSaidaAdiantamento(e.target.value)}
                          className="w-50"
                          min="0"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Form>
        </Col>

        {/* Configuração de Ocorrências de Atraso e Faltas */}
        <Col
          md={4}
          className="me-5"
          style={{
            border: '1px solid #ccc',
            padding: '10px',
            // marginRight: '15px',
            borderRadius: '8px'
          }}
        >
          <h5 style={{ marginBottom: '10px' }}>Configuração de Ocorrências de Atraso e Faltas</h5>
          <p style={{ fontSize: '14px', color: '#666' }}>
            Defina regras para notificar o gestor quando um funcionário acumular um numero
            específico de faltas, atrasos ou adiantamentos dentro de um período específico.
          </p>
          <NotificacaoExtraConfig
            numeroOcorrenciasAtraso={numeroOcorrenciasAtraso}
            setNumeroOcorrenciasAtraso={setNumeroOcorrenciasAtraso}
            periodoDiasAtraso={periodoDiasAtraso}
            setPeriodoDiasAtraso={setPeriodoDiasAtraso}
            numeroOcorrenciasAdiantamento={numeroOcorrenciasAdiantamento}
            setNumeroOcorrenciasAdiantamento={setNumeroOcorrenciasAdiantamento}
            periodoDiasAdiantamento={periodoDiasAdiantamento}
            setPeriodoDiasAdiantamento={setPeriodoDiasAdiantamento}
          />
        </Col>

        {/* Monitoramento de Atrasos em Períodos */}
        <Col
          md={3}
          style={{
            border: '1px solid #ccc',
            padding: '10px',
            borderRadius: '8px'
          }}
        >
          <h5 style={{ marginBottom: '10px' }}>Monitoramento de Atrasos em Períodos</h5>
          <p style={{ fontSize: '14px', color: '#666' }}>
            Configure o tempo máximo de atraso permitido para cada período antes que o sistema envie
            automaticamente uma notificação ao gestor.
          </p>

          <Form.Group className="mb-3">
            <Form.Label>Atraso na Entrada</Form.Label>
            <Form.Control
              type="number"
              value={minutosAtrasoEntrada}
              onChange={e => setMinutosAtrasoEntrada(e.target.value)}
              className="w-50"
              min="0"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Atraso no retorno do Café da Manhã</Form.Label>
            <Form.Control
              type="number"
              value={minutosAtrasoCafeManha}
              onChange={e => setMinutosAtrasoCafeManha(e.target.value)}
              className="w-50"
              min="0"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Atraso no retorno do Almoco</Form.Label>
            <Form.Control
              type="number"
              value={minutosAtrasoAlmoco}
              onChange={e => setMinutosAtrasoAlmoco(e.target.value)}
              className="w-50"
              min="0"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Atraso no retorno do Café da Tarde</Form.Label>
            <Form.Control
              type="number"
              value={minutosAtrasoCafeTarde}
              onChange={e => setMinutosAtrasoCafeTarde(e.target.value)}
              className="w-50"
              min="0"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Atraso na Saída</Form.Label>
            <Form.Control
              type="number"
              value={minutosAtrasoSaida}
              onChange={e => setMinutosAtrasoSaida(e.target.value)}
              className="w-50"
              min="0"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        {' '}
        <Button
          variant="primary"
          style={{ marginTop: '20px', width: '200px' }}
          onClick={handleSubmit}
        >
          Salvar
        </Button>
      </Row>
    </Container>
  )
}

export default RegrasPontualidade

export const NotificacaoExtraConfig = ({
  numeroOcorrenciasAtraso,
  setNumeroOcorrenciasAtraso,
  periodoDiasAtraso,
  setPeriodoDiasAtraso,
  numeroOcorrenciasAdiantamento,
  setNumeroOcorrenciasAdiantamento,
  periodoDiasAdiantamento,
  setPeriodoDiasAdiantamento
}) => {
  const [key, setKey] = useState('atrasos') // Controle das abas

  return (
    <>
      <Row className="mb-3 align-items-center justify-content-between">
        <Nav variant="tabs" className="d-flex w-100 justify-content-between">
          <Nav.Item>
            <Nav.Link
              eventKey="atrasos"
              active={key === 'atrasos'}
              onClick={() => setKey('atrasos')}
            >
              Atrasos
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="adiantamentos"
              active={key === 'adiantamentos'}
              onClick={() => setKey('adiantamentos')}
            >
              Adiantamentos
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Row>

      {key === 'atrasos' && (
        <div>
          <h4 className="mb-4 text-primary no-wrap">Configuração de Atrasos</h4>
          <Row>
            <Col md={12}>
              <Form.Group className="mb-3">
                <Form.Label>Número de Ocorrências</Form.Label>
                <Form.Control
                  type="number"
                  value={numeroOcorrenciasAtraso}
                  onChange={e => setNumeroOcorrenciasAtraso(e.target.value)}
                  className="w-50"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Período (em dias)</Form.Label>
                <Form.Control
                  type="number"
                  value={periodoDiasAtraso}
                  onChange={e => setPeriodoDiasAtraso(e.target.value)}
                  className="w-50"
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
      )}

      {key === 'adiantamentos' && (
        <div>
          <h4 className="mb-4 text-primary no-wrap">Configuração de Adiantamentos</h4>
          <Row>
            <Col md={12}>
              <Form.Group className="mb-3">
                <Form.Label>Número de Ocorrências</Form.Label>
                <Form.Control
                  type="number"
                  value={numeroOcorrenciasAdiantamento}
                  onChange={e => setNumeroOcorrenciasAdiantamento(e.target.value)}
                  className="w-50"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Período (em dias)</Form.Label>
                <Form.Control
                  type="number"
                  value={periodoDiasAdiantamento}
                  onChange={e => setPeriodoDiasAdiantamento(e.target.value)}
                  className="w-50"
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
      )}
    </>
  )
}

// Validação de Propriedades com PropTypes
NotificacaoExtraConfig.propTypes = {
  numeroOcorrenciasAtraso: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setNumeroOcorrenciasAtraso: PropTypes.func.isRequired,
  periodoDiasAtraso: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setPeriodoDiasAtraso: PropTypes.func.isRequired,
  numeroOcorrenciasAdiantamento: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  setNumeroOcorrenciasAdiantamento: PropTypes.func.isRequired,
  periodoDiasAdiantamento: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setPeriodoDiasAdiantamento: PropTypes.func.isRequired
}
