import { config } from '../../../../../../src/backend/configConexaoGeral.js'
import { fetchData } from '../../../../../../src/backend/model.js'

export const fetchRelatorioDetalhado = async (
  funcionarioId,
  tenantId,
  rotaBase,
  dataIntervalo = null,
  dataInicio = null,
  dataFim = null
) => {
  try {
    const url = `${config.host}/relogioPontoRelatorios`

    // Determinar as datas com base nos parâmetros fornecidos
    const inicio = dataIntervalo
      ? dataIntervalo[0]?.toISOString()
      : dataInicio?.toISOString() || null
    const fim = dataIntervalo ? dataIntervalo[1]?.toISOString() : dataFim?.toISOString() || null

    const dados = {
      tenantId,
      rota_base: rotaBase,
      requisicao: 'gerarRelatorioDetalhado',
      funcionarioId,
      dataInicio: inicio,
      dataFim: fim
    }

    console.log('Dados enviados para o backend:', dados)

    const response = await fetchData(url, dados)

    console.log('Dados detalhados recebidos do backend:', response)
    return response || []
  } catch (error) {
    console.error('Erro ao buscar relatório detalhado:', error)
    throw error
  }
}
