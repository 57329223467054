import { config } from '../../../src/backend/configConexaoGeral.js'
import { fetchData } from '../../../src/backend/model.js'
export const enviarWhatsApp = async ({ to, message, sistema }) => {
  const url = config.host + '/funcionariosAtivos' // endpoint central
  const dados = {
    requisicao: 'sendInternalWhatsApp', // a requisição será identificada no backend
    to, // número de telefone do gerente
    message, // conteúdo da mensagem
    sistema // identificação do sistema, se necessário
  }

  try {
    const data = await fetchData(url, dados)
    return data
  } catch (error) {
    throw new Error('Erro ao enviar mensagem via WhatsApp: ' + error.message)
  }
}
