import Banner from '/src/components/pages/CadastroGeral/Banner'
import logoTopo from '../Usuarios/login/logo_topo.jpg'
import { ShowAlertGeneric } from '/src/utils/form-utils.jsx'
import PropTypes from 'prop-types'
import { useState, useContext, useEffect } from 'react'
//import loadIps from '/src/components/pages/Usuarios/login/login';

import { AuthContext } from '/src/context/AuthContext'
import { useNavigate, Link } from 'react-router-dom'
import { fetchData } from '/src/backend/model'

import { config } from '/src/backend/configConexaoGeral'
import { Navbar, Nav, Tooltip, OverlayTrigger, Container, Row, Col, Button } from 'react-bootstrap'

import logoCentro from './logo_centro.jpg'

function Home() {
  const navigate = useNavigate()
  const [showAlertMessage, setShowAlertMessage] = useState(false)

  const [alertMessage, setAlertMessage] = useState('')

  function ClicklinkEntrar() {
    navigate('/login')
  }
  // const handleExperimenteClick = () => {
  //   console.log('import.meta.env.MODE', import.meta.env.MODE)
  //   if (import.meta.env.MODE === 'development') {
  //     navigate('/cadastro-tenant')
  //   } else {
  //     navigate('/precofacilmarket')
  //   }
  // }
  return (
    <div>
      <Navbar bg="light" expand="lg" className="index-top-header-home">
        <div className="d-flex align-items-center">
          <Navbar.Brand href="#home" className="d-flex align-items-center">
            <img src={logoTopo} alt="Logo" className="logo-topo-homePF" />
          </Navbar.Brand>
          <Banner
            title="Preço Fácil Market"
            fontFamily="Josefin Sans"
            color="#0bd692"
            width="42 %"
            fontSize="30px"
            classEfeito3dMouseOver=""
          />
        </div>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto menu-items-container">
            <Nav.Link href="#funcionalidades" id="index-link-nav-link">
              Funcionalidades
            </Nav.Link>
            <Nav.Link onClick={() => navigate('/planos-detalhes-pf')} id="index-link-nav-link">
              Planos sob consulta
            </Nav.Link>
            <Nav.Link href="#afiliado" id="index-link-nav-link">
              Seja um Afiliado
            </Nav.Link>
            <Nav.Link href="#contato" id="index-link-nav-link">
              Fale conosco
            </Nav.Link>

            <button
              onClick={() => navigate('/cadastro-tenant')}
              style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
            >
              <Nav.Link as="span" className="experimente-gratis-btn">
                Experimente
              </Nav.Link>
            </button>
          </Nav>
        </Navbar.Collapse>
        <CustomTooltip setAlertMessage={setAlertMessage} setShowAlertMessage={setShowAlertMessage}>
          <div className="entrar-linkPF" onClick={ClicklinkEntrar}>
            Entrar
          </div>
        </CustomTooltip>
      </Navbar>

      <ShowAlertGeneric
        showAlert={showAlertMessage}
        message={alertMessage}
        isBlurred={true}
        setShowAlert={setShowAlertMessage}
      />

      <div className="banner-container-homePF">
        <div className="sales-text-block">
          <h2 className="title-vendas">Para suas vendas online</h2>
          <h1 className="main-chamada">
            maximize seus lucros e otimize suas vendas com Preço Fácil Market
          </h1>
          <p className="detalhes">
            Preço Fácil Market é a solução definitiva para precificação inteligente, conectando você
            aos maiores marketplaces com preços sempre competitivos.
          </p>
          <Button
            variant="success"
            className="btn-experimente-gratis"
            onClick={() => navigate('/cadastro-tenant')}
          >
            começe agora
          </Button>
        </div>
        <div className="planos-circuloPF">
          <div className="preco-chamada">Planos a partir de</div>
          <div className="preco-valor">
            R$<span className="preco-destaque">159</span>,00
          </div>

          <div className="preco-info" onClick={() => navigate('/planos-detalhes-pf')}>
            saiba mais
          </div>
        </div>

        <div className="local-banner-centro">
          <img src={logoCentro} alt="Logo" className="logo-centro-homePF" />
        </div>
      </div>
    </div>
  )
}
export default Home

const CustomTooltip = ({ children, setAlertMessage, setShowAlertMessage }) => {
  // Add 'setAlertMessage' to the props validation

  CustomTooltip.propTypes = {
    children: PropTypes.node.isRequired,
    setAlertMessage: PropTypes.func.isRequired,
    setShowAlertMessage: PropTypes.func.isRequired
  }
  const [show, setShow] = useState(false)

  const [ip_atual, set_ip_atual] = useState('')
  const [ips, setIps] = useState([])

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()
  const handleSubmit = async (
    username,
    password,
    ips,
    ip_atual,
    navigate,

    contextValues // Adiciona os valores do contexto como parâmetro
  ) => {
    const {
      login,
      logout,
      setIdNivelAcesso,
      setUser,
      setSenha,
      setIsAuthenticated,
      setSuperUser,
      RotaBase,

      setTenantId
    } = contextValues // Desestrutura os valores necessários do contexto

    try {
      let url = `${config.host}/login`
      let dados = {
        username: username.trim(),
        password: password,
        rota_base: RotaBase,

        requisicao: 'login'
      }

      let log = await fetchData(url, dados)

      const ipPermitido = ips.some(ip => ip.ip_liberado === ip_atual)

      if (!ipPermitido && log.user.nivel_acesso != 'Administrador') {
        setAlertMessage('IP SEM PERMISSÃO DE ACESSO!')
        setShowAlertMessage(true)
        return
      }

      if (log && log.success) {
        setTenantId(log.user.tenantId) // seto o tenantId no contexto para ser usado em outras telas
        setIdNivelAcesso(log.user.id_nivel_acesso)
        setUser(log.user.nome_usuario)
        setSenha(log.user.email)
        login()
        setErrorMessage('')
        navigate('/inicio-pf')
      }
    } catch (error) {
      // A lógica para tratar um superusuário deve ser ajustada caso necessário
      setUser('')
      setSenha('')
      logout()
      setErrorMessage('Erro ao tentar se conectar ao servidor.')
      if (error.message) {
        const parsedError = JSON.parse(error.message.split('Erro 401: ')[1])

        setAlertMessage(parsedError.message)
        setShowAlertMessage(true)
      }
    }
  }
  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json')
        const data = await response.json()
        set_ip_atual(data.ip)
      } catch (error) {
        console.error('Ocorreu um erro ao obter o IP:', error)
      }
    }

    fetchIP()
  }, [])

  useEffect(() => {
    // Define uma função assíncrona dentro do useEffect
    const fetchIps = async () => {
      try {
        const response = await loadIps() // Chama a funcao que deve retornar os IPs
        //const response = 'teste';
        setIps(response) // Atualiza o estado com os IPs retornados
      } catch (error) {
        console.error('Ocorreu um erro ao carregar os IPs:', error)
      }
    }

    fetchIps() // Executa a função assíncrona definida
  }, [])
  const showTip = () => {
    setShow(true)
  }

  const hideTip = () => {
    setTimeout(() => {
      setShow(false)
    }, 2000)
  }
  const authContextValues = useContext(AuthContext)
  const handleFormSubmit = event => {
    event.preventDefault()

    handleSubmit(
      username,
      password,
      ips,
      ip_atual,
      navigate,

      authContextValues // Passa os valores do contexto como argumento para a função
    )
  }
  return (
    <div
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
      style={{
        position: 'relative',
        display: 'inline-block'
      }}
    >
      {children}
      {show && (
        <div className="login-container-homePF">
          <form className="login-form">
            <input
              type="text"
              placeholder="Usuário ou e-mail"
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
            <input
              type="password"
              placeholder="Senha"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <a href="#" className="forgot-password">
              Esqueceu sua senha?
            </a>
            <button type="submit" onClick={handleFormSubmit} className="login-button">
              Entrar
            </button>
          </form>
          {/* <div class='social-login'>
            <button class='facebook-login'>
              <img src='facebook-icon.png' alt='Facebook' /> Facebook
            </button>
            <button class='google-login'>
              <img src='google-icon.png' alt='Google' /> Google
            </button>
          </div> */}
        </div>
      )}
    </div>
  )
}

//--------------------------------------------
const loadIps = async () => {
  let dados = {
    requisicao: 'RetornarTodosIps'
  }
  let url = config.host + '/liberar_ips'
  let response = await fetchData(url, dados)
  return response
  // Store the IPs in the state variable
}
